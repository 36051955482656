import { template as template_72609d74526b416cbf1a0c6988411c55 } from "@ember/template-compiler";
const FKText = template_72609d74526b416cbf1a0c6988411c55(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
