import { template as template_743628bd5af24af2b17324e774eca04d } from "@ember/template-compiler";
import bodyClass from "discourse/helpers/body-class";
const AddTopicStatusClasses = template_743628bd5af24af2b17324e774eca04d(`
  {{#if @topic.invisible}}
    {{bodyClass "topic-status-unlisted"}}
  {{/if}}
  {{#if @topic.pinned}}
    {{bodyClass "topic-status-pinned"}}
  {{/if}}
  {{#if @topic.unpinned}}
    {{bodyClass "topic-status-unpinned"}}
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AddTopicStatusClasses;
