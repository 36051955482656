import { template as template_b66dd033f5cc4efd8b4c751da9488f40 } from "@ember/template-compiler";
const WelcomeHeader = template_b66dd033f5cc4efd8b4c751da9488f40(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
